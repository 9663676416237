import { Action } from '@ngrx/store';
import { type } from '../../../util';
import { IDriverLicenseResponse } from '../../../core/services/driverLicense/driverLicense.service';

export const ActionTypes = {
  LICENSE_STORE: type('[Driver License] store server response'),
  LICENSE_FIRST_PHOTO_CHANGE: type('[Driver License] first license photo loaded by user'),
  LICENSE_SECOND_PHOTO_CHANGE: type('[Driver License] second license photo loaded by user'),
  LICENSE_CONFIRM: type('[Driver License] confirm license'),
};

export class StoreLicenseData implements Action {
  public type = ActionTypes.LICENSE_STORE;

  constructor(public payload: IDriverLicenseResponse) {}
}

export class LicenseFirstPhotoChange implements Action {
  public type = ActionTypes.LICENSE_FIRST_PHOTO_CHANGE;

  constructor(public payload: string) {}
}

export class LicenseSecondPhotoChange implements Action {
  public type = ActionTypes.LICENSE_SECOND_PHOTO_CHANGE;

  constructor(public payload: string) {}
}

export class ConfirmLicense implements Action {
  public type = ActionTypes.LICENSE_CONFIRM;

  constructor(public payload: {
    firstPhotoBase64: string,
    secondPhotoBase64: string,
  }) {}
}

export type TypeAction =
  StoreLicenseData |
  LicenseFirstPhotoChange |
  LicenseSecondPhotoChange|
  ConfirmLicense;
